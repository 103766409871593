import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { StepContext, steps, Step } from '../../contexts/StepContext';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  LinkNavigation,
  ButtonPrimary,
  YourCoverReview,
  YourVehiclesReview,
  BusinessDetails,
} from '../../components/atoms';
import { Modal } from '../../components/molecules';
import { Grid, Divider, Box } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import reviewSchema from './ReviewSchema';
import { useStyles } from './ReviewStyles';
import axios from 'axios';
import clsx from 'clsx';
import { format } from 'date-fns';
import { convertDateToDlgFormat } from '../../utils/dateFormattingUtils';
import Cookies from 'js-cookie';

declare global {
  interface Window {
    gtag: any;
  }
};

export const Review: React.FC = () => {
  const { activeStep, updateActiveStep, updateShowStepper, data } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [stripeUrl, setStripeUrl] = useState('');
  
  useEffect(() => {
    updateActiveStep(5);
    updateShowStepper(true);

    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-3535201/gffleet/revfleet+standard',
    });
  }, []);

  const onSubmit = async () => {
    const formattedPolicyStartDate = data.coverStartDate ? convertDateToDlgFormat(data.coverStartDate.toString()) : '';
    const startDateForBackend = format(new Date(formattedPolicyStartDate), 'yyyy/MM/dd');

    const paymentObject = {
      Order: {
        quote: {
          //if there is a quoteId (email quote)
          quoteId: data.quoteId,
          coverOptions: data.coverOptions,
          coverType: data.coverType,
          quoteTotal: data.quoteTotal,
          startDate: startDateForBackend,
          breakdownCoverLocation: data.breakdownCoverLocation,
          vehicleRepairLocation: data.vehicleRepairLocation,
        },
        quote_id: data.quote_id,
        partnerizeRef: data.partnerizeRef,
        businessAddress: {
          companyName: data.businessCompanyName,
          firstLine: data.addressLine1,
          secondLine: data.addressLine2,
          town: data.addressLine3,
          postcode: data.postcode,
        },
        businessContacts: [
          {
            name: data.businessContactName,
            surname: data.businessContactSurname,
            telephoneNumber: data.businessContactNumber,
            emailAddress: data.businessContactEmail,
          },
          ...(data.secondPointOfContact
            ? [
                {
                  name: data.secondBusinessContactName,
                  surname: data.secondBusinessContactSurname,
                },
              ]
            : []),
        ],
        vehicles: [
          {
            vehicleCount: data.vehicleCount,
            vehicleType: data.coverType,
            vehicles: data.vehicles,
          },
        ],
        continuousInd: data.automaticRenewal
      },
    };

    try {
      const { data: response } = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_CHECKOUT_ENDPOINT}`,
        paymentObject
      );

      Cookies.remove('GFSessionControl', {path: '/'});
      Cookies.set('GFSessionControl', response.hash, {
        expires: 15 / (24 * 60), // 15 minutes
        path: '/',
        secure: true,
      });

      setStripeUrl(response.checkoutUrl);
      setPaymentModalOpen(true);
    } catch (err) {
      history.push('/failedPayment');
    }

  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    history.push(steps[Step.IMPORTANTINFO].url);
  };

  const {PaymentModalData} = reviewSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <LinkNavigation onClick={handleBack}>Back</LinkNavigation>

          <Typography variant={'h1'} className="my2">
            Please make sure all the details below are correct.
          </Typography>

          <YourCoverReview data={data} />
          <YourVehiclesReview data={data} />
          <BusinessDetails data={data} />

          <Divider className="divider"></Divider>
          <Typography variant={'h2'} className="my2">
            If everything looks good, let&apos;s go make this official.
          </Typography>
           <Box className={clsx(classes.actionButton, 'pt1')}>
            <ButtonPrimary onClick={onSubmit}>Continue to payment</ButtonPrimary>
            <Modal
              open={paymentModalOpen}
              setOpen={setPaymentModalOpen}
              componentType="fragment"
              heading={PaymentModalData.heading}
              body={PaymentModalData.body}
              buttonLabel={PaymentModalData.button}
              hasFooter={true}
              hasCallUsLink={false}
              buttonUrl={stripeUrl}
            />
          </Box>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Review;
