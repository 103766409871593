import React, { useContext, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';


import PageTemplate from '../../templates/PageTemplate';
import { DocumentTitle } from '../../components/atoms';
import { StepContext, steps, Step, initialNavStepperData } from '../../contexts/StepContext';
import { useStyles } from './ProcessingStyles';

export const Processing: React.FC = () => {
  const { updateShowStepper, updateData, updateActiveStep } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');

  useEffect(() => {
    if (!sessionId) {
      history.replace('/');
    }
    window.scrollTo(0, 0);
    updateActiveStep(5);
    updateShowStepper(false);
    if (location.pathname === '/cancel/') {
      expire();
    } else {
      ingest();
    }
  }, []);

  const retrieveQuoteData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_RETRIEVE_QUOTE_ENDPOINT}/${sessionId}/${Cookies.get('GFSessionControl')}`
      );

      if (response.data.err) {
        throw new Error(response.data.err);
      }

      const retrievedData = response.data.data;
      const quoteDataReactFormat = {
        ...initialNavStepperData,
        addressLine1: retrievedData.businessAddress.firstLine,
        addressLine2: retrievedData.businessAddress.secondLine,
        addressLine3: retrievedData.businessAddress.town,
        addressLine4: '', // Additional address info if available
        addressLine5: '', // Additional address info if available
        postcode: retrievedData.businessAddress.postcode,
        automaticRenewal: retrievedData.continuousInd, 
        coverType: retrievedData.quote.coverType, 
        quoteTotal: retrievedData.quote.quoteTotal,
        quoteId: retrievedData.quote.quoteId,
        quote_id: retrievedData.quote_id,
        quoteHash: Cookies.get('GFSessionControl'),
        moreThanFiftyVehicles: 'false', 
        breakdownCoverLocation: retrievedData.quote.breakdownCoverLocation, 
        vehicleRepairLocation: retrievedData.quote.vehicleRepairLocation,
        vehicleCount: retrievedData.vehicles.length,
        coverStartDate: retrievedData.quote.startDate,
        vehicles: retrievedData.vehicles,
        partnerizeRef: retrievedData.partnerizeRef,
        checkoutId: retrievedData.checkoutId,
        businessCompanyName: retrievedData.businessAddress.companyName,
        businessContactName: retrievedData.businessContacts[0]?.name,
        businessContactSurname: retrievedData.businessContacts[0]?.surname,
        businessContactNumber: retrievedData.businessContacts[0]?.telephoneNumber,
        businessContactEmail: retrievedData.businessContacts[0]?.emailAddress,
        secondPointOfContact: retrievedData.businessContacts.length === 2, //true or false
        secondBusinessContactName: retrievedData.businessContacts[1]?.name,
        secondBusinessContactSurname: retrievedData.businessContacts[1]?.surname,
        meetsVehicleCriteria: true, // Assuming this is a static value
        coverOptions: retrievedData.quote.coverOptions
      };

      updateData(quoteDataReactFormat);

      return quoteDataReactFormat;
    } catch (error: any) {
      history.push('/');
    }
  };

  const ingest = async () => {
    const quote = await retrieveQuoteData();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_INGEST_ENDPOINT}`,
        {
          checkoutId: quote?.checkoutId,
        },
      );

      if (response.status === 200) {
        history.push('/all-sorted');
      } else {
        history.push('/failedPayment');
      }
    } catch {
      history.push('/failedPayment');
    }
  };

  const expire = async () => {
    const quote = await retrieveQuoteData();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_EXPIRE_CHECKOUT_ENDPOINT}`,
        {
          checkoutId: quote?.checkoutId,
        },
      );

      updateActiveStep(2);
      history.push(steps[Step.REVIEW].url);
      
    } catch (err) {
      updateActiveStep(2);
      history.push(steps[Step.REVIEW].url);
    }
  };

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Processing`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} sm={5} lg={6} className={classes.gridMain}>
          <Typography className={classes.heading} variant="h1">
            ONE SECOND...
          </Typography>
          <Typography className="pt1">
            Please don&apos;t close this window or click back in the browser.
          </Typography>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default Processing;
